<template>
  <div>
    <RWCard>
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.934 16.072l6.426-5.116.158-.125a1.5 1.5 0 00.003-2.35l-6.587-5.238a1.5 1.5 0 00-1.868 0L4.475 8.485a1.5 1.5 0 00-.001 2.347l.156.124 6.436 5.117a1.5 1.5 0 001.868 0z" fill="#000" fill-rule="nonzero"/><path d="M11.056 18.67l-5.72-4.547a.903.903 0 00-1.276.154.91.91 0 00.166 1.279l6.861 5.247a1.5 1.5 0 001.823 0l6.858-5.243a.915.915 0 00.167-1.288.91.91 0 00-1.286-.155l-5.725 4.554a1.5 1.5 0 01-1.868 0z" fill="#000" opacity=".3"/></g></svg>
          VPS Products
        </h3>
      </template>
      <template v-slot:body>
        <div v-if="loading.banner">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </div>
        <div v-else>
          <b-carousel
            v-if="xhr.bannerSuccess"
            id="carousel-fade"
            style="text-shadow: 0 0 2px #000"
            fade
            indicators
            img-width="1024"
            img-height="300"
          >
            <b-carousel-slide
              v-for="(row, key) in xhr.banner"
              :key="key"
              :caption="row.caption"
              :img-src="row.image"
            ></b-carousel-slide>
          </b-carousel>
          <div class="alert alert-danger" v-else>
            {{ xhr.banner }}
          </div>
        </div>
      </template>
    </RWCard>
    <b-card>
      <template v-slot:header>
        <b-form inline @submit.stop.prevent="search">
          <label class="sr-only">Domain Name</label>
          <b-input
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="eq. mydomain.id"
            v-model="form_search.domain"
          ></b-input>

          <label class="sr-only">Username</label>
          <b-form-select
            class="mb-2 mr-sm-2 mb-sm-0"
            required
            :options="status"
            v-model="form_search.status"
          ></b-form-select>

          <b-button variant="primary" type="submit">Find</b-button>
        </b-form>
      </template>

      <!-- table -->
      <div v-if="loading.table">
        <b-skeleton-table
          :rows="pagination.limit"
          :columns="5"
          :table-props="{ striped: true }"
          v-if="loading.pagination"
        ></b-skeleton-table>
      </div>
      <div v-else>
        <div class="alert alert-danger" v-if="!xhr.vpsSuccess">
          {{ xhr.vps }}
        </div>
        <div v-else>
          <ShowProduct :data="xhr.vps" type="vps"></ShowProduct>
          <!-- pagination -->
          <b-pagination
            v-model="pagination.current"
            :total-rows="pagination.allCount"
            :per-page="pagination.limit"
            @change="handlePagination"
            align="right"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
          ></b-pagination>
        </div>
      </div>
      <!-- endtable -->
    </b-card>
  </div>
</template>
<script>
import ShowProduct from "@/views/pages/product/slot/slotProductShow";
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import { ApiURL, util } from "@/common/mixins/general.js";

export default {
  components: {
    ShowProduct,
    RWCard
  },
  mixins: [ApiURL, util],
  data() {
    return {
      loading: {
        banner: true,
        table: true
      },
      xhr: {
        banner: [],
        bannerSuccess: false,
        vps: [],
        vpsSuccess: false
      },
      form_search: {
        domain: "",
        status: "All"
      },
      status: ["All", "Active", "Suspended", "Cancelled", "Terminated"],
      pagination: {
        limit: 5,
        offset: 0,
        current: 1,
        allCount: 0
      }
    };
  },
  methods: {
    /**
     * --------------------------------------------------
     * request data banner to API
     * --------------------------------------------------
     */
    getBanner: function() {
      this.http
        .get(this.end.vps.banner)
        .then(({ data }) => {
          this.xhr.banner = data.data.banner;
          this.xhr.bannerSuccess = true;
        })
        .catch(error => {
          this.xhr.banner = error.message;
        })
        .finally(() => {
          this.loading.banner = false;
        });
    },

    /**
     * --------------------------------------------------
     * request list data vps to API
     * --------------------------------------------------
     */
    getVps: function() {
      this.xhr.vpsSuccess = false;
      let query = this.mx_ObjectToQuery(this.query_request, "?");
      this.http
        .get(this.end.vps.list, query)
        .then(({ data }) => {
          this.xhr.vps = data.data.service.record;
          this.pagination.allCount = data.data.service.count;
          this.xhr.vpsSuccess = true;
        })
        .catch(error => {
          this.xhr.vps = error.message;

          // handle ketika next page ada error
          let current = this.pagination.current;
          this.pagination.current = current !== 1 ? current - 1 : 1;
        })
        .finally(() => {
          this.loading.table = false;
        });
    },

    /**
     * -----------------------------------------
     * generate /submit pencarian (domain, status)
     * -----------------------------------------
     */
    search() {
      this.pagination.offset = 0;
      this.pagination.current = 1;
      this.loading.table = true;
      this.getVps();
    },

    /**
     * ------------------------------------------------
     * handle pagination next/prev
     * ------------------------------------------------
     */
    handlePagination: function(page) {
      this.loading.pagination = true;
      this.pagination.offset = (page - 1) * this.pagination.limit;

      this.getVps();
    }
  },
  computed: {
    /**
     * -------------------------------------------------
     * generate query request data
     * -------------------------------------------------
     */
    query_request: function() {
      let query = {};
      query.limit = this.pagination.limit;
      query.offset = this.pagination.offset;

      if (this.form_search.status !== "All") {
        query.status = this.form_search.status;
      }
      if (this.form_search.domain !== "") {
        query.search = this.form_search.domain;
      }
      return query;
    }
  },
  mounted() {
    this.getBanner();
    this.getVps();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product", route: "product" },
      { title: "VPS", route: "product/vps" }
    ]);
  }
};
</script>
